import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo from "./img/Logo (1).png";
import { Config } from '../.env.js';
import "./style.css";



// Utility function to format date as dd-mm-yyyy
// const formatDate = (date) => {
//   const day = date.getDate().toString().padStart(2, "0");
//   const month = (date.getMonth() + 1).toString().padStart(2, "0");
//   const year = date.getFullYear();
//   return `${day}-${month}-${year}`;
// };

// // Utility function to add days to a date
// const addDays = (date, days) => {
//   const result = new Date(date);
//   result.setDate(result.getDate() + days);
//   return result;
// };
const formatDate = (dateInput) => {
  const date = new Date(dateInput); // Ensure it's a Date object

  if (isNaN(date.getTime())) {
    console.error("Invalid date:", dateInput);
    return ""; // Handle invalid date gracefully
  }

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`; // Correct format for type="date"
};
const addDays = (dateInput, days) => {
  // Convert input to a Date object if it's not already one
  const date = dateInput instanceof Date ? dateInput : new Date(dateInput);

  // Check if the conversion resulted in an invalid Date
  if (isNaN(date)) {
    console.error("Invalid date:", dateInput);
    return null; // Return null or handle the error appropriately
  }

  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const CampaignForm2 = () => {
  const initialProductState = [
    {
      orderKeyword: "",
      reviewKeyword: "",
      plateformName: "",
      purchaseLink: "",
      productName: "",
      productLink: "",
      productSlot: "",
      daysToComplete: "",
      distributeSlotsEqually: false,
      date: "",
      dailySlots: [],
      weeklySlots: [],
      campaignName: "",
      id: "",
      images: null,
      productPercent: "",
      productValue: "",
      productWithfee: "",
      productWithoutfee: "",
    },
  ];
  const [copyIndex, setCopyIndex] = useState(0);
  const [products, setProducts] = useState(initialProductState);
  const [whatsappGroup, setWhatsappGroup] = useState("");

  const location = useLocation();
  const { manager } = location.state || { manager: "Unknown" };
  const { campaignName } = location.state || {};
  const { id } = location.state || {};
  const navigate = useNavigate();
  const [selectedSlotType, setSelectedSlotType] = useState(""); // Track selected slot type
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Load data from localStorage only once, when the component mounts
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      if (Array.isArray(parsedData) && parsedData.length > 0) {
        console.log("Loaded data from localStorage:", parsedData);
        setProducts(parsedData); // Set the loaded data to state
      } else {
        console.log("No valid data found in localStorage.");
      }
    }
  }, []); // Run only once when the component mounts
  // Save data to localStorage whenever 'products' state changes
  useEffect(() => {
    // Save data to localStorage whenever products state changes
    if (products !== initialProductState) {
      console.log("Saving data to localStorage:", products);
      try {
        localStorage.setItem("formData", JSON.stringify(products));
      } catch (error) {
        console.error("Error saving data to localStorage:", error);
      }
    }
  }, [products]); // This effect runs whenever products state changesns whenever the products state changes

  const handleInputChange = (index, field, value) => {
    const newProducts = [...products];
    newProducts[index][field] = value;
    if (field === "date" || field === "productSlot") {
      // Recalculate daily and weekly slots when date or productSlot changes
      newProducts[index].dailySlots = generateDailySlots(index);
      newProducts[index].weeklySlots = generateWeeklySlots(index);
      newProducts[index].monthlySlots = generateMonthlySlots(index);
    }
    setProducts(newProducts);
  };

  const handleSlotTypeChange = (e) => {
    setSelectedSlotType(e.target.value);
  };

  const handleDailySlotChange = (productIndex, slotIndex, field, value) => {
    const newProducts = [...products];
    newProducts[productIndex].dailySlots[slotIndex][field] = value;
    setProducts(newProducts);
  };

  const handleWeeklySlotChange = (productIndex, slotIndex, field, value) => {
    const newProducts = [...products];
    newProducts[productIndex].weeklySlots[slotIndex][field] = value;
    setProducts(newProducts);
  };
  const handleMonthlySlotChange = (productIndex, slotIndex, field, value) => {
    const newProducts = [...products];
    newProducts[productIndex].monthlySlots[slotIndex][field] = value;
    setProducts(newProducts);
  };

  const copyFirstItemData = () => {
    if (products.length > 0) {
      const copiedProduct = { ...products[0], id: Date.now() }; // Copy the first product with a new unique ID
      setProducts((prevProducts) => [...prevProducts, copiedProduct]); // Append to the products array
    }
  };
  const generateDailySlots = (index) => {
    const product = products[index];
    const slots = [];
    if (product.date && product.productSlot) {
      // const startDate = new Date(product.date.split('-').reverse().join('-'));
      const startDate = new Date(product.date.split(":").reverse().join("-"));
      const totalSlots = Number(product.productSlot);
      const daysToComplete = Number(product.daysToComplete)
      for (let i = 0; i < daysToComplete; i++) {
        const currentDate = addDays(startDate, i);
        slots.push({ date: formatDate(currentDate), slots: "" });
      }
    }
    return slots;
  };

  const generateWeeklySlots = (index) => {
    const product = products[index];
    const slots = [];
    if (product.date && product.productSlot) {
      // const startDate = new Date(product.date.split('-').reverse().join('-'));
      const startDate = new Date(product.date.split(":").reverse().join("-"));
      const totalDays = Number(product.daysToComplete);
      const totalSlots = Number(product.productSlot);
      const weeks = Math.ceil(totalDays / 7); // Total weeks including partial weeks

      // if totalslots%weeks == 0
      let slotsPerWeek = (totalSlots / weeks).toFixed(2);

      // else
      // slotsinFirstWeek = Math.ceil(totalslots/weeeks)
      // slotsinsecondweek = Math.ceil(totalslots-slotsinfirstweek/weeks-1)

      for (let i = 0; i < weeks; i++) {
        const weekStartDate = addDays(startDate, i * 7);
        let weekEndDate = addDays(weekStartDate, 6);
        if (i === weeks - 1) {
          // Last week, might not be a full week
          weekEndDate = addDays(startDate, totalDays - 1);
        }

        slots.push({
          startDate: formatDate(weekStartDate),
          endDate: formatDate(weekEndDate),
          dis_slots: slotsPerWeek,
        });
      }
    }
    return slots;
  };

  const generateMonthlySlots = (index) => {
    const product = products[index];
    const slots = [];
    if (product.date && product.productSlot) {
      // const startDate = new Date(product.date.split('-').reverse().join('-'));
      const startDate = new Date(product.date.split(":").reverse().join("-"));
      const totalDays = Number(product.daysToComplete);
      const totalSlots = Number(product.productSlot);
      const month = Math.ceil(totalDays / 30); // Total weeks including partial weeks

      let remainingSlots = totalSlots;

      for (let i = 0; i < month; i++) {
        const monthStartDate = addDays(startDate, i * 30);
        let monthEndDate = addDays(monthStartDate, 29);
        if (i === month - 1) {
          // Handle the last slot, which might not cover a full week
          monthEndDate = addDays(startDate, totalDays - 1);
        }

        // const slotsForMonth = Math.min(totalSlots, remainingSlots / month);
        const slotsForMonth = totalSlots / month.toFixed(2)
        slots.push({
          startDate: formatDate(monthStartDate),
          endDate: formatDate(monthEndDate),
          dis_slots: slotsForMonth.toFixed(2),
        })

        remainingSlots -= slotsForMonth;
      }
    }
    return slots;
  };

  const addBrandField = () => {
    setProducts([
      ...products,
      {
        orderKeyword: "",
        reviewKeyword: "",
        plateformName: "",
        purchaseLink: "",
        productName: "",
        productLink: "",
        productSlot: "",
        daysToComplete: "",
        distributeSlotsEqually: false,
        date: "",
        dailySlots: [],
        weeklySlots: [],
        image: null,
      },
    ]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loader
    // Create FormData object
    const formData = new FormData();
    const url = window.location.href;
    // Ensure that all products have the correct fields and slot calculation
    products.forEach((product, index) => {
      const { orderKeyword, reviewKeyword, plateformName, purchaseLink, distributeSlotsEqually, productSlot, daysToComplete, date, productLink, productName, image, } = product;

      // Calculate slots per day if required
      let slotsPerDay = null;
      if (distributeSlotsEqually && productSlot && daysToComplete) {
        slotsPerDay = (productSlot / daysToComplete).toFixed(2);
      }

      // Append product details to FormData
      formData.append(`products[${index}][orderKeyword]`, orderKeyword);
      formData.append(`products[${index}][purchaseLink]`, purchaseLink);
      formData.append(`products[${index}][reviewKeyword]`, reviewKeyword);
      formData.append(`products[${index}][plateformName]`, plateformName);
      formData.append(`products[${index}][productName]`, productName);
      formData.append(`products[${index}][productLink]`, productLink);
      formData.append(`products[${index}][productSlot]`, productSlot);
      formData.append(`products[${index}][daysToComplete]`, daysToComplete);
      formData.append(`products[${index}][distributeSlotsEqually]`, distributeSlotsEqually);
      formData.append(`products[${index}][date]`, date);
      formData.append(`products[${index}][campaignName]`, campaignName);
      formData.append(`products[${index}][id]`, id);
      formData.append(`products[${index}][url]`, URL);
      formData.append(`products[${index}][slotsPerDay]`, slotsPerDay);

      // Append image file (make sure the image is a File object)
      if (image) {
        formData.append(`images`, image); // 'images' field for multer to process
      }
    });

    // Debug log the FormData
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    // Submit the form to the backend
    try {
      const response = await axios.post(`${Config.API_URL}/api/product`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Response from server:", response.data);
      navigate("/conformation", { state: { manager, campaignName, id } });
    } catch (error) {
      console.error("Error submitting the products:", error);
    } finally {
      setLoading(false);
    }
    // Stop loader
  };


  const handleRemoveProduct = (index) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item? This action cannot be undone."
    );
    if (confirmed) {
      const newProducts = products.filter((_, i) => i !== index);
      setProducts(newProducts);
    }
  };
  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...products]; // Copy the current state
    updatedProducts[index][field] = value; // Modify the specific product field
    setProducts(updatedProducts); // Update the state with the modified product
  };
  const handleImageChange = (index, file) => {
    const newProducts = [...products];
    newProducts[index].image = file; // Store the file for later submission
    setProducts(newProducts);
  };
  // const calculateEndDate = (days) => {
  //   const today = new Date(); // Current date
  //   const endDate = new Date(today); // Create a new date object
  //   endDate.setDate(today.getDate() + parseInt(days, 10)); // Add the days to the current date
  //   return `(End Date: ${endDate.toLocaleDateString()})`; // Format the end date
  // };
  const calculateEndDate = (days) => {
    if (!days || isNaN(days)) return ""; // Validate input
    const today = new Date(); // Get the current date
    const endDate = new Date(today); // Create a new date object
    endDate.setDate(today.getDate() + parseInt(days, 10)); // Add the days to the current date
    return `(End Date: ${endDate.toLocaleDateString()})`; // Format the end date
  };

  return (
    <div className="is-flex is-justify-content-center ">
      {loading && (      //loder
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <div className="box" style={{ width: "820px", padding: "24px 10px" }}>
        <div
          className="is-flex is-justify-content-center is-flex-direction-column is-align-items-center mt-5"
          style={{ marginBottom: "67px" }}
        >
          <figure className="image is-96x96 ">
            <img src={logo} alt="logo" />
          </figure>
          <h1
            className="has-text-weight-bold mt-5 is-size-2"
            style={{ color: "#2E2F2E", marginTop: "45px" }}
          >
            Start Your Campaign
          </h1>
        </div>
        <h1>campaignName:{campaignName}</h1>
        <form onSubmit={handleSubmit}>
          {products.map((product, index) => (
            <div key={index}>
              <h2 style={{ marginTop: "20px", marginBottom: "20px" }}>
                Details of Your Item {index + 1}
              </h2>
              <div className="field is-small">
                <label className="label has-text-left"> Order Keyword*</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Order Keyword"
                    value={product.orderKeyword}
                    onChange={(e) =>
                      handleInputChange(index, "orderKeyword", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field is-small">
                <label className="label has-text-left">Review Keyword*</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Review Keyword"
                    value={product.reviewKeyword}
                    onChange={(e) =>
                      handleInputChange(index, "reviewKeyword", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field is-small">
                <label className="label has-text-left">Plateform Name*</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Plateform Name"
                    value={product.plateformName}
                    onChange={(e) =>
                      handleInputChange(index, "plateformName", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field is-small">
                <label className="label has-text-left">Purchase Link*</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Purchase Link"
                    value={product.purchaseLink}
                    onChange={(e) =>
                      handleInputChange(index, "purchaseLink", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field is-small">
                <label className="label has-text-left">Item Name*</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Product Name"
                    value={product.productName}
                    onChange={(e) =>
                      handleInputChange(index, "productName", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-left">Item Link*</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="url"
                    placeholder="Product Link"
                    value={product.productLink}
                    onChange={(e) =>
                      handleInputChange(index, "productLink", e.target.value)
                    }
                  />
                </div>
              </div>
              {/* <div className="field">
                <label className="label has-text-left">
                  Days to Complete the Slot*
                </label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Days to Complete the Slot"
                    value={product.daysToComplete}
                    onChange={(e) =>
                      handleInputChange(index, "daysToComplete", e.target.value)
                    }
                  />
                </div>
              </div> */}
              <div className="field">
                <label className="label has-text-left">
                  Days to Complete the Slot*
                </label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Days to Complete the Slot"
                    value={product.daysToComplete}
                    onChange={(e) => handleInputChange(index, "daysToComplete", e.target.value)}
                  />
                </div>
                <p className="help">
                  {product.daysToComplete && calculateEndDate(product.daysToComplete)}
                </p>
              </div>

              <div className="field">
                <label className="label has-text-left">Total Item Slot*</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Product Slot"
                    value={product.productSlot}
                    onChange={(e) =>
                      handleInputChange(index, "productSlot", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-left">Campaign Live Date*</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="date"
                    placeholder="yyyy-MM-dd"
                    value={formatDate(product.date)} // Ensure it's in the correct format
                    onChange={(e) => {
                      handleInputChange(index, "date", e.target.value); // Use the value directly
                    }}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-left">Upload Item Picture</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(index, e.target.files[0])}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={product.distributeSlotsEqually || false}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "distributeSlotsEqually",
                          e.target.checked
                        )
                      }
                    />
                    Distribute Slots Equally*
                  </label>
                </div>
              </div>
              {selectedSlotType === "daily" &&
                product.distributeSlotsEqually && (
                  <div className="field">
                    <label className="label has-text-left">Slots Per Day</label>
                    <div className="control">
                      <input
                        className="input is-rounded"
                        type="text"
                        value={(
                          (product.productSlot || 0) /
                          (product.daysToComplete || 1)
                        ).toFixed(2)}
                        readOnly
                      />
                    </div>
                  </div>
                )}

              {selectedSlotType === "daily" &&
                !product.distributeSlotsEqually && (
                  <div className="field">
                    <label className="label has-text-left">Daily Slots</label>
                    <div className="control">
                      {generateDailySlots(index).map((slot, slotIndex) => (
                        <div key={slotIndex} className="field">
                          <input
                            className="input is-rounded"
                            type="text"
                            value={slot.date}
                          />
                          <input
                            className="control is-rounded"
                            type="number"
                            placeholder="Slots"
                            value={product.dailySlots[slotIndex]?.slots || ""}
                            onChange={(e) =>
                              handleDailySlotChange(
                                index,
                                slotIndex,
                                "slots",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {selectedSlotType === "weekly" &&
                !product.distributeSlotsEqually && (
                  <div className="field">
                    <label className="label has-text-left">Weekly Slots</label>
                    <div className="control">
                      {generateWeeklySlots(index).map((slot, slotIndex) => (
                        <div key={slotIndex} className="field">
                          <input
                            className="input is-rounded"
                            type="text"
                            value={`${slot.startDate} to ${slot.endDate}`}
                          />
                          <input
                            className="control is-rounded "
                            type="number"
                            placeholder="Slots"
                            value={product.weeklySlots[slotIndex]?.slots || ""}
                            onChange={(e) =>
                              handleWeeklySlotChange(
                                index,
                                slotIndex,
                                "slots",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {selectedSlotType === "weekly" &&
                product.distributeSlotsEqually && (
                  <div className="field">
                    <label className="label has-text-left">Weekly Slots</label>
                    <div className="control">
                      {generateWeeklySlots(index).map((slot, slotIndex) => (
                        <div key={slotIndex} className="field">
                          <input
                            className="input is-rounded"
                            type="text"
                            value={`${slot.startDate} to ${slot.endDate}`}
                          />
                          <input
                            className="control is-rounded "
                            type="number"
                            placeholder="Slots"
                            value={slot.dis_slots}
                            readOnly
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              {selectedSlotType === "monthly" &&
                !product.distributeSlotsEqually && (
                  <div className="field">
                    <label className="label has-text-left">Monthly</label>
                    <div className="control">
                      {generateMonthlySlots(index).map((slot, slotIndex) => (
                        <div key={slotIndex} className="field">
                          <input
                            className="input is-rounded"
                            type="text"
                            value={`${slot.startDate} to ${slot.endDate}`}
                          />
                          <input
                            className="control is-rounded "
                            type="number"
                            placeholder="Slots"
                            value={product.monthlySlots[index]?.slots || ""}
                            onChange={(e) =>
                              handleMonthlySlotChange(
                                index,
                                slotIndex,
                                "slots",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              {selectedSlotType === "monthly" &&
                product.distributeSlotsEqually && (
                  <div className="field">
                    <label className="label has-text-left">Monthly</label>
                    <div className="control">
                      {generateMonthlySlots(index).map((slot, slotIndex) => (
                        <div key={slotIndex} className="field">
                          <input
                            className="input is-rounded"
                            type="text"
                            value={`${slot.startDate} to ${slot.endDate}`}
                          />
                          <input
                            className="control is-rounded "
                            type="number"
                            placeholder="Slots"
                            value={slot.dis_slots}
                            readOnly
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              <div className="field">
                <div className="control">
                  <div className="colomn">
                    <div className="my-2 has-text-left">
                      <strong>Define Slots*</strong>
                    </div>
                    <div
                      className="checkboxContainer"
                      style={{ display: "inline-flex", flexDirection: "row" }}
                    >
                      <input
                        type="radio"
                        value="monthly"
                        checked={selectedSlotType === "monthly"}
                        onChange={handleSlotTypeChange}
                        className="checkbox"
                      />
                      <label className="checkboxLabel">Monthly</label>

                      <input
                        type="radio"
                        value="weekly"
                        checked={selectedSlotType === "weekly"}
                        onChange={handleSlotTypeChange}
                        className="checkbox"
                      />
                      <label className="checkboxLabel">Weekly</label>

                      <input
                        type="radio"
                        value="daily"
                        checked={selectedSlotType === "daily"}
                        onChange={handleSlotTypeChange}
                        className="checkbox"
                      />
                      <label className="checkboxLabel">Daily</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="field">
                <label className="label has-text-left">how much % or absolute value refund will be done*</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder=""
                    value={product.productPercent}
                    onChange={(e) =>
                      handleInputChange(index, "productPercent", e.target.value)
                    }
                  />
                </div>
              </div> */}
              <div className="field">
                <label className="label has-text-left">Refund Percentage or Absolute Value*</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Refund percentage"
                    value={product.productValue}
                    onChange={(e) =>
                      handleInputChange(index, "productValue", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-left">product amount (with delivery fee)</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Product amount"
                    value={product.productWithfee}
                    onChange={(e) =>
                      handleInputChange(index, "productWithfee", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-left">product amount (without delivery fee) </label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Product Amount without delivery fee"
                    value={product.productWithoutamount}
                    onChange={(e) =>
                      handleInputChange(index, "productWithoutamount", e.target.value)
                    }
                  />
                </div>
              </div>

              <label className="radio">
                <input
                  type="radio"
                  name="whatsappGroup"
                  value="yes"
                  onChange={(e) => setWhatsappGroup(e.target.value)}
                />
                Move Incomplete Slots to Next Day/Week/Month
              </label>
              <div className="field">
                <div className="control">
                  <button
                    type="button"
                    className="button is-danger is-rounded"
                    onClick={() => handleRemoveProduct(index)}
                  >
                    Delete Item
                  </button>
                </div>
              </div>
            </div>

          ))}
          <div className="field">
            <button
              type="button"
              className="button is-primary mt-3 is-rounded"
              onClick={addBrandField}
            >
              Add More Items
            </button>
          </div>

          <button
            type="button"
            className="button is-warning mt-3 is-rounded"
            onClick={copyFirstItemData}
          >
            Copy First Item
          </button>

          <div className="field">
            <div className="control is-flex is-justify-content-center">
              <button
                className="button is-link  mt-3"
                type="submit"
                style={{ width: "30%" }}
              >
                {loading ? (
                  <div className="loader"></div> // Loader
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CampaignForm2;