import React, { useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Config } from '../.env.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./style.css";
// import logo from '../logo192.png';
const UpdatePassword = () => {

    const [Password, setpassword] = useState("");
    // password validations regx
    let hasSixChar = Password.length >= 6;
    let hasLowerChar = /[a-z]/.test(Password);
    let hasUpperChar = /[A-Z]/.test(Password);
    let hasNumber = /[0-9]/.test(Password);
    let hasSpacialChar = /(.*[^a-zA-Z0-9].*)/.test(Password);
    const [ConfirmPassword, setconfirmpassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const email = location.state?.email;
    const navigate = useNavigate();
    const handleUpdatePassword = async () => {
        setLoading(true);
        try {
            // Validate password and confirmPassword here if needed

            const response = await fetch(`${Config.API_URL}/api/ppp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: Password,
                    confirmPassword: ConfirmPassword,
                }),
            });

            const data = await response.json();
            console.log(email)

            if (data.success) {
                // Password updated successfully, you can handle this case
                toast.success('Password updated successfully');
                navigate("/signin", { replace: true });
            } else {
                // Handle failed password update, you can display an error message
                toast.error('Failed to update password:', data.error);
                console.log(data)
            }
        } catch (error) {
            toast.error('Error updating password:', error);
        } finally {
            setLoading(false); // Hide loade
        }
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword); // Toggle showPassword state
    };
    return (
        <div className='thank-you-container'>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <div className='container1 has-text-centered mt-5'>
                <div className='logo-card'>
                    <figure className="image is-64x64">
                        {/* <img src={logo} alt="Logo" /> */}
                    </figure>
                </div>
                < div className='container' >
                    <h1 className='title mt-5 has-text-white' >Update Password
                    </h1>
                    <div className='field my-2 has-text-left my-input'>
                        <label className="label has-icons-right  has-text-white" > Password</label>
                        <div className="control has-icons-left has-icons-right my-input">

                            <div className='colomn'>
                                <input className="input is-rounded "
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    value={Password}
                                    onChange={(e) => setpassword(e.target.value)}
                                />

                                <span className="icon is-small is-left">
                                    <i className="fas fa-key"></i>
                                </span>
                                <span className="icon is-small is-right" onClick={togglePasswordVisibility} style={{ cursor: 'pointer', 'pointer-events': 'all' }}>
                                    <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
                                </span>
                            </div>

                        </div>
                    </div>

                    <div className='field my-2 has-text-left my-input'>
                        <label className="label has-icons-right  has-text-white" >Confirm Password</label>
                        <div className="control has-icons-left has-icons-right my-input">
                            <div className='colomn'>
                                <input className="input is-rounded "
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Confirm Password"
                                    value={ConfirmPassword}
                                    onChange={(e) => setconfirmpassword(e.target.value)}

                                />
                                <span className="icon is-small is-right" onClick={togglePasswordVisibility} style={{ cursor: 'pointer', 'pointer-events': 'all' }}>
                                    <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
                                </span>

                                {Password && ConfirmPassword && (
                                    <div className='has-text-left'>
                                        <small>
                                            {Password === ConfirmPassword ? (<span className='has-text-success icon is-small is-left'>

                                                <i className="fas fa-check-circle"></i>
                                            </span>
                                            ) : (<span className='has-text-danger icon is-small is-right'>

                                                <i className='fas fa-times-circle '></i>
                                            </span>
                                            )}
                                        </small>

                                    </div>

                                )}

                            </div>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control ">

                            <button className="button is-primary is-rounded  is-focused is-medium my-input " disabled={!Password || !ConfirmPassword || Password !== ConfirmPassword} onClick={handleUpdatePassword}>
                                {loading ? (
                                    <div className="loader"></div> // Loader
                                ) : (
                                    'Update Password'
                                )}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>



    )
}

export default UpdatePassword
