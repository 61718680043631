import React, { useState } from 'react';

const PersonalDetails = ({
    gender,
    langaugeSpeek,
    langaugeWrite,
    Phone,
    whatsapp,
    email,
    profileType,
    onGenderChange,
    onlangaugeSpeekChange,
    onlangaugeWriteChange,
    OnPhoneChange,
    onwhatsappChange,
    onemailChange,
    onProfileTypeChange,

}) => {
    return (
        <div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={email}
                        onChange={(e) => onemailChange(e.target.checked)}
                    />
                    Your Email
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={Phone}
                        onChange={(e) => OnPhoneChange(e.target.checked)}
                    />
                    Your Phone Number
                </label>
            </div>

            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={whatsapp}
                        onChange={(e) => onwhatsappChange(e.target.checked)}
                    />
                    Your Whatsapp Number
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={gender}
                        onChange={(e) => onGenderChange(e.target.checked)}
                    />
                    Your Gender
                </label>
            </div>

            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={langaugeSpeek}
                        onChange={(e) => onlangaugeSpeekChange(e.target.checked)}
                    />
                    Which language can you speek?
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={langaugeWrite}
                        onChange={(e) => onlangaugeWriteChange(e.target.checked)}
                    />
                    Which language can you write?
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                    />
                    Is your
                    <input
                        className="input is-small"
                        type="text"
                        value={profileType}
                        onChange={(e) => onProfileTypeChange(e.target.value)}
                        placeholder="select type"
                        style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
                    />
                    Profile Public/Private
                </label>


            </div>
        </div>
    )
}

export default PersonalDetails