import React, { useState } from 'react';

const SocialQuestion = ({
    FullName,
    instagram,
    facebook,
    twitter,
    youTube,
    customSocial,
    customMarketPlaceName,
    customForoum,
    onFullNameChange,
    onInstagramChange,
    onFacebookChange,
    onTwitterChange,
    onYoutubeChange,
    oncustomSocialChange,
    oncustomMarketPlaceNameChange,
    oncustomForoumChange,
}) => {

    return (
        <div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={FullName}
                        onChange={(e) => onFullNameChange(e.target.checked)}
                    />
                    Your Full Name
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={instagram}
                        onChange={(e) => onInstagramChange(e.target.checked)}
                    />
                    Your Instagram User Name
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={youTube}
                        onChange={(e) => onYoutubeChange(e.target.checked)}
                    />
                    Your YouTube User Name
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={twitter}
                        onChange={(e) => onTwitterChange(e.target.checked)}
                    />
                    Your Twitter User Name
                </label>
            </div>

            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={facebook}
                        onChange={(e) => onFacebookChange(e.target.checked)}
                    />
                    Your Facebook User Name
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                    />
                    Your
                    <input
                        className="input is-small"
                        type="text"
                        value={customSocial}
                        onChange={(e) => oncustomSocialChange(e.target.value)}
                        placeholder=" Custom Social Media Platform"
                        style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
                    />
                    User Name
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                    />
                    Your
                    <input
                        className="input is-small"
                        type="text"
                        value={customMarketPlaceName}
                        onChange={(e) => oncustomMarketPlaceNameChange(e.target.value)}
                        placeholder="Custom MarketPlace Name"
                        style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
                    />
                    Account Name
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                    />
                    Your
                    <input
                        className="input is-small"
                        type="text"
                        value={customForoum}
                        onChange={(e) => oncustomForoumChange(e.target.value)}
                        placeholder="Custom Forum"
                        style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
                    />
                    Profile Link
                </label>
            </div>
        </div>
    )
}
export default SocialQuestion