import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Config } from '../.env.js';
import { useLocation } from 'react-router-dom';

const Table = () => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const campaignName = location.state?.campaignName;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Config.API_URL}/api/refund-order`, {
          params: { campaignName }, // Pass campaignName as a query parameter
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (campaignName) {
      fetchData();
    }
  }, [campaignName]);

  const handleApprove = async (orderId) => {
    try {
      // Make API call to approve the order
      await axios.post(`${Config.API_URL}/api/approve-order`,);
      // Optionally, you can update the local state to reflect the change
      setData((prevData) => prevData.map(item =>
        item.orderId === orderId ? { ...item, status: 'Approved' } : item
      ));
    } catch (error) {
      console.error('Error approving order:', error);
    }
  };

  const handleCancel = async (orderId) => {
    try {
      // Make API call to cancel the order
      await axios.post(`${Config.API_URL}/api/cancel-order`, { orderId });
      // Optionally, you can update the local state to reflect the change
      setData((prevData) => prevData.map(item =>
        item.orderId === orderId ? { ...item, status: 'Canceled' } : item
      ));
    } catch (error) {
      console.error('Error canceling order:', error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="table-container" style={{ overflowX: 'auto' }}>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th className="customStyle">Full Name</th>
              <th className="customStyle">Instagram</th>
              <th className="customStyle">Facebook</th>
              <th className="customStyle">Twitter</th>
              <th className="customStyle">YouTube</th>
              <th className="customStyle">Custom Social</th>
              <th className="customStyle">Gender</th>
              <th className="customStyle">Whatsapp</th>
              <th className="customStyle">Phone</th>
              <th className="customStyle">Custom MarketPlace</th>
              <th className="customStyle">Custom Review</th>
              <th className="customStyle">Custom Profile Link</th>
              <th className="customStyle">Email</th>
              <th className="customStyle">Order</th>
              <th className="customStyle">Quantity</th>
              <th className="customStyle">Product Amount</th>
              <th className="customStyle">Date</th>
              <th className="customStyle">Student</th>
              <th className="customStyle">College</th>
              <th className="customStyle">Manager</th>
              <th className="customStyle">Whatsapp Group</th>
              <th className="customStyle">Pin Code</th>
              <th className="customStyle">City</th>
              <th className="customStyle">Product Question</th>
              <th className="customStyle">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className="customStyle">{item.FullName}</td>
                <td className="customStyle">{item.AuthInstagram}</td>
                <td className="customStyle">{item.AuthFacebook}</td>
                <td className="customStyle">{item.AuthTwitter}</td>
                <td className="customStyle">{item.AuthYouTube}</td>
                <td className="customStyle">{item.AuthCustomSocial}</td>
                <td className="customStyle">{item.gender}</td>
                <td className="customStyle">{item.whatsapp}</td>
                <td className="customStyle">{item.Phone}</td>
                <td className="customStyle">{item.customMarketPlaceName}</td>
                <td className="customStyle">{item.review}</td>
                <td className="customStyle">{item.customForoum}</td>
                <td className="customStyle">{item.email}</td>
                <td className="customStyle">{item.order}</td>
                <td className="customStyle">{item.quantity}</td>
                <td className="customStyle">{item.productAmount}</td>
                <td className="customStyle">{item.date}</td>
                <td className="customStyle">{item.student}</td>
                <td className="customStyle">{item.college}</td>
                <td className="customStyle">{item.manger}</td>
                <td className="customStyle">{item.whatsappGroup}</td>
                <td className="customStyle">{item.pinCode}</td>
                <td className="customStyle">{item.city}</td>
                <td className="customStyle">{item.productSS}</td>
                <td className="customStyle">
                  <button onClick={() => handleApprove(item.orderId)} className="button is-primary">Approve</button>
                  <button onClick={() => handleCancel(item.orderId)} className="button is-danger ml-2">Cancel</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
