// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import { getAllCampaigns } from '../components/api2';
// import Card from '../pages/card';
// import "./style.css";

// const ITEMS_PER_PAGE = 10;
// const PAGES_DISPLAYED = 5;

// const LiveCustomer = () => {
//     const location = useLocation();
//     const [campaigns, setCampaigns] = useState([]);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [filter, setFilter] = useState('all');
//     const [filteredCampaigns, setFilteredCampaigns] = useState([]);

//     useEffect(() => {
//         const fetchCampaigns = async () => {
//             try {
//                 const allCampaigns = await getAllCampaigns();
//                 console.log('API response:', allCampaigns); // Debugging log to check API response

//                 // Remove filtering by 'approve' field
//                 setCampaigns(allCampaigns);
//                 setFilteredCampaigns(allCampaigns);
//             } catch (error) {
//                 console.error('Error fetching campaigns:', error);
//             }
//         };

//         fetchCampaigns();
//     }, [location]); // Add location dependency if route change affects fetching
//     useEffect(() => {
//         // Apply filtering logic based on selected filter
//         if (filter === 'all') {
//             setFilteredCampaigns(campaigns);
//         } else if (filter === 'appliedUsers') {
//             const appliedCampaigns = campaigns.filter((campaign) => campaign.appliedUsers === true); // Adjust filtering condition
//             setFilteredCampaigns(appliedCampaigns);
//         }
//     }, [filter, campaigns]);
//     const totalPages = Math.ceil(filteredCampaigns.length / ITEMS_PER_PAGE);
//     const startPage = Math.max(1, Math.min(currentPage - Math.floor(PAGES_DISPLAYED / 2), totalPages - PAGES_DISPLAYED + 1));
//     const endPage = Math.min(totalPages, startPage + PAGES_DISPLAYED - 1);
//     const paginatedCampaigns = filteredCampaigns.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

//     const handlePageChange = (page) => {
//         setCurrentPage(page);
//     };

//     return (
//         <div className='container1'>
//             {/* <div className="buttons is-right">
//                 <button
//                     className={`button ${filter === 'applied' ? 'is-primary' : ''}`}
//                     onClick={() => setFilter('applied')}
//                 >
//                     My Applied Campaigns
//                 </button>
//                 <button
//                     className={`button ${filter === 'all' ? 'is-primary' : ''}`}
//                     onClick={() => setFilter('all')}
//                 >
//                     All Campaigns
//                 </button>
//             </div> */}
//             <h1 className='title is-1 has-text-centered'><b>Brand Collabs For You</b></h1>
//             <h2 className='subtitle is-3 has-text-centered mt-3'>BROUGHT TO YOU WITH LOVE FROM HOBO.VIDEO</h2>
//             <div className="button is-centered">
//                 <div className="tabs is-centered">
//                     <ul style={{ display: 'flex', gap: '10px' }}> {/* Add spacing between buttons */}
//                         <li className={`is-active`}>
//                             <button
//                                 className={`button ${filter === 'applied' ? 'is-primary' : ''}`}
//                                 onClick={() => setFilter('applied')}
//                                 style={{ borderRadius: '5px', padding: '7px' }}
//                             >
//                                 <span> My Applied Campaigns</span>
//                             </button>
//                         </li>
//                         <li>
//                             <button
//                                 className={`button ${filter === 'all' ? 'is-primary' : ''}`}
//                                 onClick={() => setFilter('all')}
//                                 style={{ borderRadius: '5px', padding: '7px' }}
//                             >
//                                 <span> All Campaigns</span>
//                             </button>
//                         </li>
//                     </ul>
//                 </div>

//             </div>
//             <div className="columns is-multiline is-centered">
//                 {paginatedCampaigns.length > 0 ? (
//                     paginatedCampaigns.map((campaign, index) => (
//                         <div key={index} className="column is-half">
//                             <Card campaign={campaign} alignLeft={index % 2 === 0} />
//                         </div>
//                     ))
//                 ) : (
//                     <div>No campaigns to display</div>
//                 )}
//             </div>
//             <div className="pagination is-centered" role="navigation" aria-label="pagination">
//                 {currentPage > 1 && (
//                     <button className="pagination-previous" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
//                 )}
//                 {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
//                     <button key={page} className={`pagination-link ${page === currentPage ? 'is-current' : ''}`} aria-label={`Goto page ${page}`} onClick={() => handlePageChange(page)}>
//                         {page}
//                     </button>
//                 ))}
//                 {currentPage < totalPages && (
//                     <button className="pagination-next" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
//                 )}
//             </div>
//         </div >
//     );
// };

// export default LiveCustomer;

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import navigate for redirection
import { getAllCampaigns } from '../components/api2';
import Card from '../pages/card';
import './style.css';

const ITEMS_PER_PAGE = 10;
const PAGES_DISPLAYED = 5;

const LiveCustomer = () => {
    const location = useLocation();
    const navigate = useNavigate(); // For navigation
    const [campaigns, setCampaigns] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState('all');
    const [filteredCampaigns, setFilteredCampaigns] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Track authentication status

    // useEffect(() => {
    //     // Check if user is authenticated
    //     const checkAuth = () => {
    //         const token = localStorage.getItem('token'); // Replace with your auth check logic
    //         if (!token) {
    //             navigate('/live-user'); // Redirect to login if not authenticated
    //         } else {
    //             setIsLoggedIn(true);
    //         }
    //     };

    //     checkAuth();
    // }, [navigate]);

    useEffect(() => {
        // if (isLoggedIn) {
        const fetchCampaigns = async () => {
            try {
                const allCampaigns = await getAllCampaigns();
                console.log('API response:', allCampaigns); // Debugging log to check API response

                // Remove filtering by 'approve' field
                setCampaigns(allCampaigns);
                setFilteredCampaigns(allCampaigns);
            } catch (error) {
                console.error('Error fetching campaigns:', error);
            }
        };

        fetchCampaigns();

    }, [location]); // Add location dependency if route change affects fetching

    useEffect(() => {
        // Apply filtering logic based on selected filter
        if (filter === 'all') {
            setFilteredCampaigns(campaigns);
        } else if (filter === 'appliedUsers') {
            const appliedCampaigns = campaigns.filter((campaign) => campaign.appliedUsers === true); // Adjust filtering condition
            setFilteredCampaigns(appliedCampaigns);
        }
    }, [filter, campaigns]);

    const totalPages = Math.ceil(filteredCampaigns.length / ITEMS_PER_PAGE);
    const startPage = Math.max(1, Math.min(currentPage - Math.floor(PAGES_DISPLAYED / 2), totalPages - PAGES_DISPLAYED + 1));
    const endPage = Math.min(totalPages, startPage + PAGES_DISPLAYED - 1);
    const paginatedCampaigns = filteredCampaigns.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // if (!isLoggedIn) {
    //     return null; // Or a loading spinner if you want
    // }

    return (
        <div className='container1'>
            <h1 className='title is-1 has-text-centered'><b>Brand Collabs For You</b></h1>
            <h2 className='subtitle is-3 has-text-centered mt-3'>BROUGHT TO YOU WITH LOVE FROM HOBO.VIDEO</h2>
            <div className="button is-centered">
                <div className="tabs is-centered">
                    <ul style={{ display: 'flex', gap: '10px' }}> {/* Add spacing between buttons */}
                        <li className={`is-active`}>
                            <button
                                className={`button ${filter === 'applied' ? 'is-primary' : ''}`}
                                onClick={() => setFilter('applied')}
                                style={{ borderRadius: '5px', padding: '7px' }}
                            >
                                <span> My Applied Campaigns</span>
                            </button>
                        </li>
                        <li>
                            <button
                                className={`button ${filter === 'all' ? 'is-primary' : ''}`}
                                onClick={() => setFilter('all')}
                                style={{ borderRadius: '5px', padding: '7px' }}
                            >
                                <span> All Campaigns</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="columns is-multiline is-centered">
                {paginatedCampaigns.length > 0 ? (
                    paginatedCampaigns.map((campaign, index) => (
                        <div key={index} className="column is-half">
                            <Card campaign={campaign} alignLeft={index % 2 === 0} />
                        </div>
                    ))
                ) : (
                    <div>No campaigns to display</div>
                )}
            </div>
            <div className="pagination is-centered" role="navigation" aria-label="pagination">
                {currentPage > 1 && (
                    <button className="pagination-previous" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                )}
                {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
                    <button key={page} className={`pagination-link ${page === currentPage ? 'is-current' : ''}`} aria-label={`Goto page ${page}`} onClick={() => handlePageChange(page)}>
                        {page}
                    </button>
                ))}
                {currentPage < totalPages && (
                    <button className="pagination-next" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                )}
            </div>
        </div >
    );
};

export default LiveCustomer;
