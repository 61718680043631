import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import languageOptions from "../languageOptions.json";
import { Config } from '../../.env.js';



const PersonalDetailsUser = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const campaignName = queryParams.get('campaignName');
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [formData, setFormData] = useState(() => {
        // Load saved data from localStorage if available
        const savedData = localStorage.getItem('formData');
        return savedData ? JSON.parse(savedData) : {
            FullName: '',
            review: '',
            customMarketPlaceName: '',
            customForoum: '',
            whatsappGroup: '',
            city: '',
            state: '',
            AuthInstagram: '',
            AuthFacebook: '',
            AuthTwitter: '',
            AuthYouTube: '',
            gender: '',
            langaugeWrite: '',
            langaugeSpeek: '',
            collegeName: '',
            whatsapp: '',
            email: '',
            date: '',
            manager: '',
            pinCode: '',
            accountNumber: '',
            ifsc: '',
            BeneficiaryName: '',
            BankName: '',
            productSS: '',
            inputValue: '',
            quantity: '',
            productAmount: '',
            inputValue3: '',
            inputValue4: '',
            order: '',
            Phone: '',
            student: '',
            AuthCustomSocial: '',
            profileType: '',
            submitedDate: '',
            campaignName: '',
            url: '',
        };
    });
    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]);
    let hasValNumber = /^[0-9]{1,10}$/.test(formData.Phone);
    let hasValWhatsapp = /^[0-9]{1,10}$/.test(formData.whatsapp);
    let hasValEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(formData.email);
    const validOrderId = /^[a-zA-Z0-9]*$/.test(formData.order);

    const customStyles1 = {
        control: (provided) => ({
            ...provided,
            borderRadius: '20px',
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '20px',
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: state.isFocused ? '20px' : '0',
        }),
        multiValue: (provided) => ({
            ...provided,
            borderRadius: '20px',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            borderRadius: '20px',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            borderRadius: '20px',
        }),
    };
    const handleFormChange = (eventOrValue, actionMeta) => {
        // Check if this is the Select component's event
        if (Array.isArray(eventOrValue)) {
            const { name } = actionMeta; // Get the 'name' of the Select input
            setFormData(prevData => ({
                ...prevData,
                [name]: eventOrValue, // Update with the selected options array
            }));
        } else {
            // Handle regular inputs
            const { name, value, type, checked } = eventOrValue.target;
            setFormData(prevData => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };

    useEffect(() => {
        if (campaignName) {
            const fetchData = async () => {
                try {
                    const response = await axios.get(`${Config.API_URL}/api/FormData/${campaignName}`);


                    console.log('Fetched data:', response.data);


                    const filteredData = response.data.reduce((acc, item) => ({ ...acc, ...item }), {});

                    setData(filteredData);
                } catch (error) {
                    console.error('Error fetching form data:', error);
                }
            };
            fetchData();
        }
    }, [campaignName]);
    return (
        <div>
            {data.FullName === true && (
                <div className="field">
                    <label className="label">Your Full Name</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            name="FullName"
                            placeholder="Name"
                            value={formData.FullName}
                            onChange={handleFormChange}
                            required
                        />
                    </div>
                </div>
            )}
            {data.instagram === true && (
                <div className="field">
                    <label className="label">Your Instagram User Name</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            name="AuthInstagram"
                            placeholder="Username"
                            value={formData.instagram}
                            onChange={handleFormChange}
                            required
                        />
                    </div>
                </div>
            )}
            {data.youTube === true && (
                <div className="field">
                    <label className="label">Your YouTube User Name</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            name=" AuthYouTube"
                            placeholder="Username"
                            value={formData.youTube}
                            onChange={handleFormChange}
                            required
                        />
                    </div>
                </div>
            )}
            {data.twitter === true && (
                <div className="field">
                    <label className="label">Your Twitter User Name</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            name="AuthTwitter"
                            placeholder="Username"
                            value={formData.twitter}
                            onChange={handleFormChange}
                            required
                        />
                    </div>
                </div>
            )}

            {data.facebook === true && (
                <div className="field">
                    <label className="label">Your Facebook User Name</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            name="AuthFacebook"
                            placeholder="Username"
                            value={formData.facebook}
                            onChange={handleFormChange}
                            required
                        />
                    </div>
                </div>
            )}
            {data.customSocial && data.customSocial !== false && data.customSocial.trim() !== "" && (
                <div className='field'>
                    <label className="label">  Your {data.customSocial} User Name</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            name="AuthCustomSocial"
                            placeholder="User Name"
                            value={formData.customSocial}
                            onChange={handleFormChange}
                            required
                        />
                    </div>
                </div>
            )}

            {data.customMarketPlaceName && data.customMarketPlaceName !== false && data.customMarketPlaceName.trim() !== "" && (
                <div className='field'>
                    <label className="label">{data.customMarketPlaceName} Account Name</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            name="customMarketPlaceName"
                            placeholder="Account Name"
                            value={formData.customMarketPlaceName}
                            onChange={handleFormChange}
                            required
                        />
                    </div>
                </div>
            )}
            {data.customForoum && data.customForoum !== false && data.customForoum.trim() !== "" && (
                <div className='field'>
                    <label className="label">{data.customForoum} Profile Link</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            name="customForoum"
                            placeholder="Profile Link"
                            value={formData.customForoum}
                            onChange={handleFormChange}
                            required
                        />
                    </div>
                </div>
            )}
            {data.email === true && (
                <div className="field">
                    <label className="label"> Your Email</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            name="email"
                            placeholder="abc@abc.com"
                            value={formData.email}
                            onChange={handleFormChange}
                            required
                        />
                    </div>
                    {formData.email && !hasValEmail && (
                        <div>
                            <small
                                className={`ml-1 mt-1  ${!hasValNumber && formData.email !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                {!hasValNumber && formData.email !== '' ? 'Please Enter valid Mobile number' : ''}
                            </small>
                        </div>
                    )}

                </div>
            )}
            {data.Phone === true && (
                <div className="field">
                    <label className="label">Your Phone Number</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            name="Phone" // Removed the space before 'Phone'
                            placeholder="9999999999"
                            value={formData.Phone}
                            onChange={handleFormChange}
                            required
                        />
                    </div>
                    {formData.Phone && !hasValNumber && (
                        <div>
                            <small
                                className={`ml-1 mt-1  ${!hasValNumber && formData.Phone !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                {!hasValNumber && formData.Phone !== '' ? 'Please Enter valid Mobile number' : ''}
                            </small>
                        </div>
                    )}
                </div>
            )}
            {data.whatsapp === true && (
                <div className="field">
                    <label className="label"> Your Whatsapp Number</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            name="whatsapp"
                            placeholder="9999999999"
                            value={formData.whatsapp}
                            onChange={handleFormChange}
                            required
                        />
                    </div>
                    {formData.whatsapp && !hasValWhatsapp && (
                        <div>
                            <small
                                className={`ml-1 mt-1  ${!hasValNumber && formData.whatsapp !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                {!hasValNumber && formData.whatsapp !== '' ? 'Please Enter valid Whatsapp Number number' : ''}
                            </small>
                        </div>
                    )}
                </div>
            )}
            {data.gender === true && (
                <div className="field">
                    <label className="label">Your Gender</label>
                    <div className="control">
                        <label className="radio">
                            <input
                                type="radio"
                                name="gender"
                                value="male"
                                checked={formData.gender === 'male'}
                                onChange={handleFormChange}
                                required
                            />
                            Male
                        </label>
                        <label className="radio">
                            <input
                                type="radio"
                                name="gender"
                                value="female"
                                checked={formData.gender === 'female'}
                                onChange={handleFormChange}
                            />
                            Female
                        </label>
                        <label className="radio">
                            <input
                                type="radio"
                                name="gender"
                                value="other"
                                checked={formData.gender === 'other'}
                                onChange={handleFormChange}
                            />
                            Other
                        </label>
                    </div>
                </div>
            )}
            {data.langaugeSpeek === true && (

                <div className='field'>
                    <div className="control">
                        <div className='colomn'>
                            <label className="label"> Which language can you write?</label>
                            <Select
                                isMulti
                                value={formData.langaugeSpeek}
                                name="langaugeSpeek"
                                onChange={(selectedOptions) =>
                                    handleFormChange(selectedOptions, { name: 'langaugeSpeek' })
                                }
                                options={languageOptions}
                                className="basic-multi-select is-rounded"
                                classNamePrefix="select"
                                styles={customStyles1}
                            />
                        </div>
                    </div>
                </div>
            )}
            {data.langaugeWrite === true && (
                <div className='field'>
                    <div className="control">
                        <div className='colomn'>
                            <label className="label"> Which language can you speak?</label>
                            <Select
                                isMulti
                                value={formData.langaugeWrite}
                                name="langaugeSpeek"
                                onChange={(selectedOptions) =>
                                    handleFormChange(selectedOptions, { name: 'langaugeWrite' })
                                }
                                options={languageOptions}
                                className="basic-multi-select is-rounded"
                                classNamePrefix="select"
                                styles={customStyles1}
                            />
                        </div>
                    </div>
                </div>
            )}

            {data.profileType && data.profileType !== false && data.profileType.trim() !== "" && (
                <div className='field'>
                    <label className="label">Is your profile type {data.profileType}?</label>
                    <div className="control">
                        <label className="radio">
                            <input
                                type="radio"
                                name="profileType"
                                value="yes"
                                checked={formData.profileType === 'yes'}
                                onChange={handleFormChange}
                            />
                            Yes
                        </label>
                        <label className="radio">
                            <input
                                type="radio"
                                name="profileType"
                                value="no"
                                checked={formData.profileType === 'no'}
                                onChange={handleFormChange}
                            />
                            No
                        </label>
                    </div>
                </div>
            )}

        </div>
    )
}

export default PersonalDetailsUser