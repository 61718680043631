import React, { useState } from 'react';

const Address = ({
    city,
    state,
    pinCode,
    onCityChange,
    onStateChange,
    onPincodeChnage,
}) => {

    return (
        <>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={city}
                        onChange={(e) => onCityChange(e.target.checked)}
                    />
                    City
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={state}
                        onChange={(e) => onStateChange(e.target.checked)}
                    />
                    State
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={pinCode}
                        onChange={(e) => onPincodeChnage(e.target.checked)}
                    />
                    Pin Code
                </label>
            </div>
        </>
    )
}

export default Address
