import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Config } from '../.env.js';
import { useLocation } from 'react-router-dom';

const ApproverTable = () => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const location = useLocation();
  const [filter, setFilter] = useState(''); // Track the selected filter
  // const [filteredData, setFilteredData] = useState([]);
  const [campaignFilter, setCampaignFilter] = useState(''); // Selected campaign filter
  const [filteredData, setFilteredData] = useState([]);
  const [campaigns, setCampaigns] = useState([]); // List of unique campaign names

  const campaignName = location.state?.campaignName;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Config.API_URL}/api/refund`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const response = await axios.get(`${Config.API_URL}/api/refund-order`);
        setData1(response.data);

        // Extract unique campaign names
        const uniqueCampaigns = [...new Set(response.data.map((item) => item.campaignName))];
        setCampaigns(uniqueCampaigns);
      } catch (error) {
        console.error('Error fetching data1:', error);
      }
    };

    fetchData1();
  }, []);

  // Filter data based on the selected campaign
  useEffect(() => {
    if (campaignFilter) {
      const filtered = data.filter((item, index) => data1[index]?.campaignName === campaignFilter);
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [campaignFilter, data, data1]);

  const handleCampaignChange = (e) => {
    setCampaignFilter(e.target.value);
  };




  const handleApprove = async (contactId) => {
    try {
      const payload = { contactId }; // Replace 'orderId' with 'contactId'

      console.log('Payload:', payload); // Log the payload to verify the contactId is correct

      const response = await axios.post(`${Config.API_URL}/api/approve-payment`, payload);

      setData((prevData) =>
        prevData.map((item) =>
          item.contactId === contactId ? { ...item, status: 'Approved' } : item
        )
      );
      console.log('Approval submitted');
    } catch (error) {
      console.error('Error approving contactId:', error);
      console.log('Response:', error.response?.data);
    }
  };
  const handleCancel = async (orderId) => {
    try {
      await axios.post(`${Config.API_URL}/api/cancel-order`, { orderId });
      setData((prevData) =>
        prevData.map((item) =>
          item.orderId === orderId ? { ...item, status: 'Canceled' } : item
        )
      );
    } catch (error) {
      console.error('Error canceling order:', error);
    }
  };
  const [filteredCampaigns, setFilteredCampaigns] = useState(campaigns);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [campaignNameFilter, setCampaignNameFilter] = useState("");
  const [sortBy, setSortBy] = useState("date"); // Default sort by date
  const [groupByCampaign, setGroupByCampaign] = useState(false);

  const statusOptions = [
    "Pending Approval",
    "Rechecking",
    "Duplicate",
    "Failed Payments",
  ];

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    filterCampaigns(campaignNameFilter, event.target.value);
  };

  const handleCampaignNameChange = (event) => {
    setCampaignNameFilter(event.target.value);
    filterCampaigns(event.target.value, selectedStatus);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
    sortCampaigns(filteredCampaigns, event.target.value);
  };

  const handleGroupChange = () => {
    setGroupByCampaign((prev) => !prev);
  };

  const filterCampaigns = (name, status) => {
    let filtered = campaigns;

    if (name) {
      filtered = filtered.filter((campaign) =>
        campaign.campaignName.toLowerCase().includes(name.toLowerCase())
      );
    }

    if (status) {
      filtered = filtered.filter((campaign) => campaign.status === status);
    }

    sortCampaigns(filtered, sortBy);
  };

  const sortCampaigns = (campaigns, sortOption) => {
    let sortedCampaigns = [...campaigns];

    if (sortOption === "date") {
      sortedCampaigns = sortedCampaigns.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (sortOption === "campaign") {
      sortedCampaigns = sortedCampaigns.sort((a, b) => a.campaignName.localeCompare(b.campaignName));
    }

    setFilteredCampaigns(sortedCampaigns);
  };

  const renderCampaigns = () => {
    if (groupByCampaign) {
      const groupedCampaigns = filteredCampaigns.reduce((acc, campaign) => {
        if (!acc[campaign.campaignName]) {
          acc[campaign.campaignName] = [];
        }
        acc[campaign.campaignName].push(campaign);
        return acc;
      }, {});

      return Object.keys(groupedCampaigns).map((campaignName) => (
        <div key={campaignName}>
          <h3>{campaignName}</h3>
          <ul>
            {groupedCampaigns[campaignName].map((campaign) => (
              <li key={campaign.id}>
                {campaign.campaignName} - {campaign.status} - {campaign.createdAt}
              </li>
            ))}
          </ul>
        </div>
      ));
    } else {
      return filteredCampaigns.map((campaign) => (
        <li key={campaign.id}>
          {campaign.campaignName} - {campaign.status} - {campaign.createdAt}
        </li>
      ));
    }
  };

  return (
    <div className="container mt-5">
      {/* Campaign Filter Dropdown */}
      <div className="filters mb-3">
        <label htmlFor="campaignFilter" className="mr-2">Filter by Campaign:</label>
        <select
          id="campaignFilter"
          value={campaignFilter}
          onChange={handleCampaignChange}
          className="dropdown"
        >
          <option value="">All Campaigns</option>
          {campaigns.map((campaign, index) => (
            <option key={index} value={campaign}>
              {campaign}
            </option>
          ))}
        </select>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label>Filter by Status: </label>
        <select value={selectedStatus} onChange={handleStatusChange}>
          <option value="">All</option>
          {statusOptions.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </select>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label>Sort by: </label>
        <select value={sortBy} onChange={handleSortChange}>
          <option value="date">Date</option>
          <option value="campaign">Campaign</option>
        </select>
      </div>

      {/* Group by campaign name */}
      <div style={{ marginBottom: "10px" }}>
        <label>Group by Campaign Name</label>
        <input
          type="checkbox"
          checked={groupByCampaign}
          onChange={handleGroupChange}
        />
      </div>
      <div className="table is-bordered" style={{ overflowX: 'auto' }}>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th className="customStyle">Campaign Name</th>
              <th className="customStyle">User Name</th>
              <th className="customStyle">Order ID</th>
              <th className="customStyle">Quantity</th>
              <th className="customStyle">Invoice Number</th>
              <th className="customStyle">Product Amount</th>
              <th className="customStyle">Deliverable Link</th>
              <th className="customStyle">Bank Name</th>
              <th className="customStyle">Ifsc</th>
              <th className="customStyle">Beneficiary Name</th>
              <th className="customStyle">Account Number</th>
              <th className="customStyle">UPI Number</th>
              <th className="customStyle">Manager</th>
              <th className="customStyle">Date</th>
              <th className="customStyle">Student</th>
              <th className="customStyle">College</th>
              <th className="customStyle">Whatsapp Group</th>
              <th className="customStyle">Pin Code</th>
              <th className="customStyle">City</th>
              <th className="customStyle">Product Name</th>
              <th className="customStyle">Order Delivered Screen Shot</th>
              <th className="customStyle">Submitting Rating SS</th>

              {/* New Columns for Data1 */}
              <th className="customStyle">Order ID</th>
              <th className="customStyle">Full Name</th>
              <th className="customStyle">Instagram</th>
              <th className="customStyle">Facebook</th>
              <th className="customStyle">Twitter</th>
              <th className="customStyle">YouTube</th>
              <th className="customStyle">Custom Social</th>
              <th className="customStyle">Gender</th>
              {/* <th className="customStyle">Whatsapp</th> */}
              {/* <th className="customStyle">Phone</th> */}
              <th className="customStyle">Custom MarketPlace</th>
              <th className="customStyle">Custom Review</th>
              <th className="customStyle">Custom Profile Link</th>
              <th className="customStyle">Email</th>

              <th className="customStyle">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 && data1 && data1.length > 0 ? (
              // data.map((item, index) => (

              filteredData.map((item, index) => (
                <tr key={index}>




                  <td className="customStyle">{data1[index]?.campaignName || ''}</td>

                  <td className="customStyle">
                    {data1[index]?.AuthInstagram ? (
                      <a
                        href={`https://www.instagram.com/${data1[index].AuthInstagram}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'blue', textDecoration: 'underline' }}
                      >
                        {data1[index].AuthInstagram}
                      </a>
                    ) : (
                      ''
                    )}
                  </td>


                  {/* <td className="customStyle">{data1[index]?.FullName || ''}</td>
                 
                  <td className="customStyle">{data1[index]?.AuthFacebook || ''}</td>
                  <td className="customStyle">{data1[index]?.AuthTwitter || ''}</td>
                  <td className="customStyle">{data1[index]?.AuthYouTube || ''}</td>
                  <td className="customStyle">{data1[index]?.AuthCustomSocial || ''}</td>
                  <td className="customStyle">{data1[index]?.gender || ''}</td>
                  <td className="customStyle">{data1[index]?.whatsapp || ''}</td>
                  <td className="customStyle">{data1[index]?.Phone || ''}</td>
                 
                  <td className="customStyle">{data1[index]?.email || ''}</td> */}
                  {/* Data from `data` */}
                  <td className="customStyle">{item.order}</td>
                  <td className="customStyle">{item.inputValue1}</td>
                  <td className="customStyle">{item.invoiceReceived}</td>
                  <td className="customStyle">{data1[index]?.productAmount || ''}</td>
                  <td className="customStyle">{item.inputValue2}</td>
                  <td className="customStyle">{item.bankName}</td>
                  <td className="customStyle">{item.ifsc}</td>
                  <td className="customStyle">{item.beneficiaryName}</td>
                  <td className="customStyle">{item.accountNumber}</td>
                  <td className="customStyle">{item.UPI}</td>
                  <td className="customStyle">{item.manger}</td>
                  <td className="customStyle">{data1[index]?.date || ''}</td>
                  <td className="customStyle">{item.student}</td>
                  <td className="customStyle">{data1[index]?.collegeName || ''}</td>
                  <td className="customStyle">{item.whatsappGroup}</td>
                  <td className="customStyle">{data1[index]?.pinCode || ''}</td>
                  <td className="customStyle">{data1[index]?.city || ''}</td>
                  <td className="customStyle">{item.inputValue}</td>
                  <td className="customStyle">
                    {item.inputValue3 && (
                      <a href={`${Config.API_URL}/${item.inputValue3}`} target="_blank" rel="noopener noreferrer">
                        <img
                          src={`${Config.API_URL}/${item.inputValue3}`}
                          alt="Screenshot 1"
                          style={{ width: '100px', height: 'auto' }}
                        />
                      </a>
                    )}
                  </td>
                  <td className="customStyle">
                    {item.inputValue4 && (
                      <a href={`${Config.API_URL}/${item.inputValue4}`} target="_blank" rel="noopener noreferrer">
                        <img
                          src={`${Config.API_URL}/${item.inputValue4}`}
                          alt="Screenshot 2"
                          style={{ width: '100px', height: 'auto' }}
                        />
                      </a>
                    )}
                  </td>


                  {/* Data from `data1`, corresponding to the same row */}
                  <td className="customStyle">{data1[index]?.order || ''}</td>
                  <td className="customStyle">{data1[index]?.FullName || ''}</td>
                  <td className="customStyle">{data1[index]?.AuthInstagram || ''}</td>
                  <td className="customStyle">
                    {data1[index]?.AuthFacebook ? (
                      <a
                        href={`https://www.facebook.com/${data1[index].AuthFacebook}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'blue', textDecoration: 'underline' }}
                      >
                        {data1[index].AuthFacebook}
                      </a>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="customStyle">
                    {data1[index]?.AuthTwitter ? (
                      <a
                        href={`https://www.x.com/${data1[index].AuthTwitter}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'blue', textDecoration: 'underline' }}
                      >
                        {data1[index].AuthTwitter}
                      </a>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="customStyle">
                    {data1[index]?.AuthYouTube ? (
                      <a
                        href={`https://www.youtube.com/${data1[index].AuthYouTube}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'blue', textDecoration: 'underline' }}
                      >
                        {data1[index].AuthYouTube}
                      </a>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="customStyle">{data1[index]?.AuthCustomSocial || ''}</td>
                  <td className="customStyle">{data1[index]?.gender || ''}</td>
                  {/* <td className="customStyle">{data1[index]?.whatsapp || ''}</td> */}
                  {/* <td className="customStyle">{data1[index]?.Phone || ''}</td> */}
                  <td className="customStyle">{data1[index]?.customMarketPlaceName || ''}</td>
                  <td className="customStyle">{data1[index]?.review || ''}</td>
                  <td className="customStyle">{data1[index]?.customForoum || ''}</td>
                  <td className="customStyle">{data1[index]?.customMarketPlaceName || ''}</td>
                  <td className="customStyle">
                    <button
                      onClick={() => handleApprove(item.contactId)}
                      disabled={item.status === 'Approved' || item.status === 'Canceled'} // Disable if approved or canceled
                      className={`button is-primary ${item.status === 'Approved' ? 'is-light' : ''}`}
                    >
                      Approve
                    </button>

                    <button
                      onClick={() => handleCancel(item.order)}
                      disabled={item.status === 'Canceled' || item.status === 'Approved'} // Disable if canceled or approved
                      className={`button is-danger ${item.status === 'Canceled' ? 'is-light' : ''}`}
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="33">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ApproverTable;

