import React from 'react'

const OrderDetails = ({
    order,
    date,
    submitedDate,
    review,
    onorderChange,
    onDateChange,
    onsubmitedDateChange,
    onReviewChange,
}) => {
    return (
        <div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={order}
                        onChange={(e) => onorderChange(e.target.checked)}
                    />
                    Order Id
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={date}
                        onChange={(e) => onDateChange(e.target.checked)}
                    />
                    Order Date
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                    />
                    <input
                        className="input is-small"
                        type="text"
                        value={submitedDate}
                        onChange={(e) => onsubmitedDateChange(e.target.value)}
                        placeholder="01-01-2025"
                        style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
                    />
                    Submission Date
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                    />
                    How Many
                    <input
                        className="input is-small"
                        type="text"
                        value={review} // bind to `review` state
                        onChange={(e) => onReviewChange(e.target.value)} // update `review` state
                        placeholder="Custom MarketPlace Name"
                        style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
                    />
                    Reviews you do per month
                </label>
            </div>

        </div>
    )
}

export default OrderDetails