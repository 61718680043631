import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Config } from '../.env.js';

const CampaignDetails = ({ campaign = "Hello", alignLeft }) => {
    const { campaignName, managers, image } = campaign;
    const [product, setProduct] = useState(null);

    const [formData, setFormData] = useState({
        AuthContact: '',
        AuthEmail: '',
        productName: '',
        // Add other fields as needed
    });
    useEffect(() => {
        // Fetch campaign data
        axios.get(`${Config.API_URL}/api/getCampaign`)
            .then(response => {
                console.log('Campaign Data:', response.data); // Log to check the response
                const campaignData = response.data[1]; // Select the second campaign for example, or apply filtering logic
                if (campaignData) {
                    setFormData(prevState => ({
                        ...prevState,
                        AuthContact: campaignData.campaignName,
                        AuthEmail: campaignData.campaignRulesDescription,
                    }));
                }
            })
            .catch(error => {
                console.error('Error fetching campaign data:', error);
            });

        // Fetch product data

    }, []);
    useEffect(() => {
        const fetchProduct = async () => {
            try {
                console.log("Fetching product for campaignName:", campaignName);
                const response = await axios.get(`${Config.API_URL}/api/product`, {
                    params: { campaignName } // Pass campaignName as query parameter
                });
                console.log("API Response:", response.data);
                setProduct(response.data[0]); // Assuming the API returns an array, take the first product
            } catch (error) {
                console.error("Error fetching product:", error);
            }
        };

        if (campaignName) {
            fetchProduct();
        }
    }, [campaignName]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
    };

    return (
        <div className='container'>
            <div className='container1 mt-5'>
                <figure className="image is-64x64"></figure>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="field">
                    <h1 className='title has-text-centered mt-5'>Campaign</h1>
                </div>

                {/* Non-editable Campaign Name */}
                <div className='field is-small'>
                    <label className="label has-text-left">Campaign Name*</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            placeholder="Campaign Name"
                            value={formData.AuthContact || ''} // Default to empty string if undefined
                            readOnly
                        />
                    </div>
                </div>

                {/* Non-editable Campaign Rules Description */}
                <div className='field'>
                    <label className="label has-text-left">Campaign Rules Description*</label>
                    <div className="control">
                        <textarea
                            className="textarea"
                            style={{ borderRadius: "20px" }}
                            value={formData.AuthEmail}
                            readOnly
                            placeholder="Campaign rule description"
                        ></textarea>
                    </div>
                </div>

                {/* Non-editable Product Name */}
                <div className="field is-small">
                    <label className="label has-text-left">Item Name*</label>
                    <p className="has-text-grey-light">Item Name</p>
                    <p className='has-text-weight-bold'>{product ? product.productName : "Loading..."}</p>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            placeholder="Product Name"
                            value={formData.productName}
                            readOnly
                        />
                    </div>
                </div>

                {/* Submit Button */}
                {/* <div className="field">
                    <div className="control">
                        <button className="button is-primary is-fullwidth mt-5 is-rounded" type="submit">Submit</button>
                    </div>
                </div> */}
            </form>
        </div>
    );
};

export default CampaignDetails;
