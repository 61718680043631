import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Input, Button, Modal, Select, Form, Layout, Menu } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, DashboardOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const { Sider, Content } = Layout;

const AdminPanel = () => {
    const [users, setUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [form] = Form.useForm();
    const [selectedMenu, setSelectedMenu] = useState("user-management");
    const [collabs, setCollabs] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedMenu === "user-management") {
            fetchUsers();
        } else if (selectedMenu === "collab") {
            fetchCollabs();
        }
    }, [selectedMenu]);

    const fetchUsers = async () => {
        try {
            const response = await axios.get("http://localhost:9001/api/users");
            setUsers(response.data);  // Ensure state updates correctly
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const handleAddUser = () => {
        setEditingUser(null);
        form.resetFields();
        setIsModalOpen(true);
    };

    const handleEditUser = (record) => {
        setEditingUser(record);
        form.setFieldsValue(record);
        setIsModalOpen(true);
    };

    const handleDeleteUser = async (userId) => {
        try {
            await axios.delete(`http://localhost:9001/api/users/${userId}`);
            fetchUsers();
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };
    const handleEditCollab = (record) => {
        setEditingUser(record);
        form.setFieldsValue(record);
        setIsModalOpen(true);
    };

    const handleDeleteCollab = async (id) => {
        try {
            await axios.delete(`http://localhost:9001/api/post-collab/${id}`);
            fetchCollabs();
        } catch (error) {
            console.error("Error deleting collab:", error);
        }
    };

    const handleFormSubmit = async (values) => {
        try {
            if (editingUser) {
                await axios.put(`http://localhost:9001/api/users/${editingUser.id}`, values);
            } else {
                await axios.post("http://localhost:9001/api/users", values);
            }
            fetchUsers();
            setIsModalOpen(false);
        } catch (error) {
            console.error("Error saving user:", error);
        }
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <div className="flex gap-2">
                    <Button icon={<EditOutlined />} onClick={() => handleEditUser(record)} />
                    <Button icon={<DeleteOutlined />} danger onClick={() => handleDeleteUser(record.id)} />
                </div>
            ),
        },
    ];
    const fetchCollabs = async () => {
        try {
            const response = await axios.get("http://localhost:9001/api/post-collab");
            setCollabs(response.data);
        } catch (error) {
            console.error("Error fetching collaborations:", error);
        }
    };

    const collabColumns = [
        { title: "userId", dataIndex: "userId", key: "userId" },
        { title: "Title", dataIndex: "title", key: "title" },
        { title: "Description", dataIndex: "description", key: "description" },
        { title: "Required Followers", dataIndex: "requiredFollowers", key: "requiredFollowers" },
        {
            title: "Platforms",
            dataIndex: "platforms",
            key: "platforms",
            render: (platforms) => platforms ? platforms.join(", ") : "-"
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <div className="flex gap-2">
                    <Button icon={<EditOutlined />} onClick={() => handleEditCollab(record)} />
                    <Button icon={<DeleteOutlined />} danger onClick={() => handleDeleteCollab(record._id)} />
                </div>
            ),
        },

    ];

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Sider width={250} theme="dark">
                <Menu
                    mode="vertical"
                    theme="dark"
                    selectedKeys={[selectedMenu]}
                    onClick={({ key }) => {
                        setSelectedMenu(key);
                        if (key === "dashboard") {
                            window.location.href = "https://influencer.hobo.video/user";
                        }
                    }}
                >
                    <Menu.Item key="dashboard" icon={<DashboardOutlined />}>Dashboard</Menu.Item>
                    <Menu.Item key="user-management" icon={<UserOutlined />}>User Management</Menu.Item>
                    <Menu.Item key="settings" icon={<SettingOutlined />}>Settings</Menu.Item>
                    <Menu.Item key="collab" icon={<SettingOutlined />}>All Collab</Menu.Item>
                    <Menu.Item key="report" icon={<SettingOutlined />}>Reported Profile</Menu.Item>
                </Menu>
            </Sider>

            <Layout>
                <Content style={{ padding: "20px" }}>
                    {selectedMenu === "user-management" && (
                        <div>
                            <div className="flex justify-between mb-4">
                                <h1 className="text-xl font-bold">User Management</h1>
                                <Button type="primary" icon={<PlusOutlined />} onClick={handleAddUser}>
                                    Add User
                                </Button>
                            </div>
                            <Table columns={columns} dataSource={users} rowKey="id" />

                            <Modal
                                title={editingUser ? "Edit User" : "Add User"}
                                open={isModalOpen}
                                onCancel={() => setIsModalOpen(false)}
                                footer={null}
                            >
                                <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
                                    <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter the name" }]}>
                                        <Input placeholder="Enter name" />
                                    </Form.Item>
                                    <Form.Item name="email" label="Email" rules={[{ required: true, message: "Please enter the email" }]}>
                                        <Input placeholder="Enter email" />
                                    </Form.Item>
                                    <Form.Item name="role" label="Role" rules={[{ required: true, message: "Please select a role" }]}>
                                        <Select placeholder="Select role">
                                            <Option value="Admin">Admin</Option>
                                            <Option value="Manager">Influencer/Creator</Option>
                                        </Select>
                                    </Form.Item>
                                    <div className="flex justify-end gap-2">
                                        <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                                        <Button type="primary" htmlType="submit">
                                            {editingUser ? "Save Changes" : "Add User"}
                                        </Button>
                                    </div>
                                </Form>
                            </Modal>
                        </div>
                    )}
                    {selectedMenu === "dashboard" && <h1>Dashboard Content</h1>}
                    {selectedMenu === "settings" && <h1>Settings Content</h1>}
                    {selectedMenu === "settings" && <h1>report profile</h1>}
                    {selectedMenu === "collab" && (
                        <div>
                            <h1>All Collaborations</h1>
                            <Table columns={collabColumns} dataSource={collabs} rowKey="_id" />
                        </div>
                    )}
                </Content>
            </Layout>
        </Layout>
    );
};

export default AdminPanel;
