import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signup } from '../components/api2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../pages/style.css";
// import logo from '../logo192.png';

const SignUp = () => {
    const navigate = useNavigate();
    const [yourName, setYourName] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [phone, setPhone] = useState("");
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");

    const hasValNumber = /^[0-9]{1,10}$/.test(phone);
    const restrictedDomains = ['gmail.com', 'outlook.com', 'hotmail.com', 'yahoo.com', 'aol.com'];

    const isBusinessEmail = (email) => {
        const domain = email.split('@')[1];
        return domain && !restrictedDomains.includes(domain.toLowerCase());
    };

    const hasValEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u.test && isBusinessEmail(Email); const [loading, setLoading] = useState(false);
    const isValidPassword = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,}$/.test(Password);
    // const isValidPassword = /^(?=.*[0-9])[a-zA-Z0-9]{5,}$/.test(Password);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const capitalizeWords = (name) => {
        return name
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };
    const handleSignup = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loader
        try {
            const formattedName = capitalizeWords(yourName);
            const res = await signup({ yourName: formattedName, email: Email, password: Password, phone });
            if (res.error) {
                toast.error(res.error);
            } else {
                toast.warning("Enter your OTP");
                navigate('/otp', { replace: true, state: { email: Email } });
            }
        } catch (error) {
            // Handle other errors
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className='thank-you-container'>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <div className='control my-input' style={{ marginLeft: "29rem" }}>
                <h1 className='title has-text-white'>Sign Up </h1>
                <div className='form-container'>
                    <form>
                        <div className='my-3 has-text-left my-input'>
                            <label className='label has-text-white ml-4'>Your Name</label>
                        </div>
                        <div className='field'>
                            <div className="control has-icons-left ">

                                <input
                                    className="input is-rounded"
                                    type="text"
                                    placeholder="e.g. Naresh kumar"
                                    value={yourName}
                                    onChange={(e) => setYourName(e.target.value)}
                                />
                                <span className='icon is-small is-left'>
                                    <i className='fas fa-rocket'></i>
                                </span>

                            </div>
                        </div>

                        <div className=' my-3 has-text-left '>
                            <label className='label has-text-white ml-4'>Mobile Number</label>
                        </div>
                        <div className='field'>
                            <div className="control has-icons-left my-input">

                                <input
                                    className="input is-rounded"
                                    type="text"
                                    placeholder="9898989898"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                {phone && !hasValNumber && (
                                    <div>
                                        <small className={`ml-1  ${!hasValNumber && phone !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                            {!hasValNumber && phone !== '' ? 'Enter a valid 10-digit mobile number' : ''}
                                        </small>
                                    </div>
                                )}
                                <span className='icon is-small is-left'>
                                    <i className='fas fa-phone'></i>
                                </span>

                            </div>
                        </div>

                        <div className='my-3 has-text-left my-input'>
                            <label className='label has-text-white ml-4'>Your Business Email</label>
                        </div>
                        <div className='field'>
                            <div className='control has-icons-left my-input'>

                                <input
                                    className="input is-rounded"
                                    type="text"
                                    placeholder="naresh.kumar@hobo.video"
                                    value={Email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {Email && !hasValEmail && (
                                    <small className={`ml-1  ${!hasValEmail && Email !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                                        {!hasValEmail && Email !== '' ? 'Enter a valid email address' : ''}
                                    </small>
                                )}
                                <span className="icon is-small is-left">
                                    <i className="fas fa-envelope"></i>
                                </span>


                            </div>
                        </div>

                        <div className='my-3 has-text-left my-input'>
                            <label className='label has-text-white ml-4'>Password</label>
                        </div>
                        <div className='field'>
                            <div className="control has-icons-left has-icons-right my-input">
                                <input
                                    className="input is-rounded"
                                    type="text"
                                    style={{ WebkitTextSecurity: showPassword ? "none" : "disc" }}
                                    placeholder="Password"
                                    value={Password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span className="icon is-small is-left">
                                    <i className="fas fa-key"></i>
                                </span>
                                <span className="icon is-small is-right" onClick={togglePasswordVisibility} style={{ cursor: 'pointer', 'pointer-events': 'all' }}>
                                    <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
                                </span>
                            </div>
                            {Password && !isValidPassword && (
                                <small className="has-text-danger">
                                    Password must be at least 5 characters long and contatin one numerical digit.
                                </small>
                            )}

                        </div>
                        <div className='is-flex has-text-left mr-5 mt-4 my-input'>
                            <p className="has-text-white"> Have an account?</p>
                            <Link to='/signin'>
                                <span className='has-text-weight-bold ml-2'>Sign In</span>
                            </Link>
                        </div>
                        <div className='Link mb-2'>
                            <Link to='/forgot-password'>
                                <p className='has-text-right my-input'>Forgot Your Password?</p>
                            </Link>
                        </div>
                        <div className="field">
                            <div className="control my-input">
                                <div className="field">
                                    <div className="control my-input">
                                        <Link to="/otp">
                                            <button
                                                className="button has-text-white is-primary is-rounded is-focused is-medium is-fullwidth"
                                                disabled={!yourName || !phone || !Email || !Password || loading || !hasValNumber || !hasValEmail}
                                                onClick={handleSignup}
                                            >
                                                {loading ? (
                                                    <div className="loader"></div> // Loader
                                                ) : (
                                                    'Sign Up'
                                                )}
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SignUp;

