import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Config } from '../.env.js';
import { useLocation, useNavigate } from 'react-router-dom';
import BankDropdown from './bank.js';
import "./style.css";
import IFSCSearch from './findifsc.js';
const Form2 = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const campaignName = queryParams.get('campaignName');
  const navigate = useNavigate();

  const [data, setData] = useState({});
  // const [fullName, setFullName] = useState('');
  // const [orderId, setOrderId] = useState('');
  // const [upiId, setUpiId] = useState('');
  // const [bankAccountNumber, setBankAccountNumber] = useState('');
  // const [ifscCode, setIfscCode] = useState('');
  // const [beneficiaryName, setBeneficiaryName] = useState('');
  // const [bankName, setBankName] = useState('');
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [selectedBank, setSelectedBank] = useState('');
  const [products, setProducts] = useState([{ name: '', quantity: '', ss: null, amount: '' }]);
  const [formData, setFormData] = useState(() => {
    // Load initial form data from localStorage
    const savedFormData = localStorage.getItem('formData');
    return savedFormData ? JSON.parse(savedFormData) : {
      AuthCustomSocial: '',
      productSS: '',
      order: '',
      city: '',
      pinCode: '',
      UPI: '',
      paymentMethod: '',
      accountNumber: '',
      ifsc: '',
      beneficiaryName: '',
      bankName: '',
      whatsappGroup: '',
      inputValue: '',
      inputValue1: '',
      inputValue2: '',
      inputValue3: '',
      inputValue4: '',
      starRating: '',
      sellerFeedbackScreenshot: '',
      invoiceReceived: '',
      invoiceNumber: '',
      profileVisibility: '',
      product1: '',
      contactId: ''
    };
  });
  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);
  useEffect(() => {
    if (campaignName) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${Config.API_URL}/api/templategetREfund/${campaignName}`);


          console.log('Fetched data:', response.data);

          const filteredData = response.data.reduce((acc, item) => ({ ...acc, ...item }), {});

          setData(filteredData);
        } catch (error) {
          console.error('Error fetching form data:', error);
        }
      };
      fetchData();
    }
  }, [campaignName]);
  const handleClearStorage = () => {
    localStorage.clear();
    setFormData({
      AuthCustomSocial: '',
      productSS: '',
      order: '',
      city: '',
      pinCode: '',
      UPI: '',
      paymentMethod: '',
      accountNumber: '',
      ifsc: '',
      beneficiaryName: '',
      bankName: '',
      whatsappGroup: '',
      inputValue: '',
      inputValue1: '',
      inputValue2: '',
      inputValue3: '',
      inputValue4: '',
      starRating: '',
      sellerFeedbackScreenshot: '',
      invoiceReceived: '',
      invoiceNumber: '',
      profileVisibility: '',
      product1: '',
      contactId: ''
    });
  };
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${Config.API_URL}/api/product`, {
          params: { campaignName }, // Send campaign name as a query parameter
        });
        setProducts(response.data); // Set fetched products to state
      } catch (error) {
        console.error("Error fetching products:", error);
        console.log("Error fetching products:", error);
      }
    };

    if (campaignName) {
      fetchProducts(); // Fetch products when campaignName changes
    }
  }, [campaignName]);
  const handleFormChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowMessage(true);
    setLoading(true); // Start loader
    console.log('Form data being submitted:', formData);

    const formPayload = new FormData();

    // Append form data fields to formPayload
    for (const key in formData) {
      formPayload.append(key, formData[key]);
    }

    // Append files separately
    if (formData.inputValue3) {
      formPayload.append('productSS', formData.inputValue3); // Name must match backend multer field
    }
    // if (formData.sellerFeedbackScreenshot) {
    //     formPayload.append('sellerFeedbackScreenshot', formData.sellerFeedbackScreenshot); // Name must match backend multer field
    // }

    try {
      const response = await axios.post(`${Config.API_URL}/api/submit-form`, formPayload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setTimeout(() => {
        navigate('/live-user');
      }, 1500);
      console.log('Form submitted successfully:', response.data);
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error, e.g., display error message
    } finally {
      setLoading(false);
    } // Stop loader
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        inputValue3: file,  // Use appropriate state key for each file input
      }));
    }
  };

  const handleFileUpload1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        inputValue4: file,
      }));
    }
  };

  const handleFileUpload2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        sellerFeedbackScreenshot: file,
      }));
    }
  };
  const handleBankChange = (e) => {
    setSelectedBank(e.target.value);
  };
  return (
    <div className='container'>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <button type="button" className="button is-danger" onClick={handleClearStorage}>
          Clear All Data
        </button>
        <h2> Refund Form {campaignName}</h2>
        <div className="field">
          <label className="label"> Contact Id</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              name="contactId"
              placeholder="Contact id reciving on mail after submisson of order form"
              value={formData.contactId}
              onChange={handleFormChange}
            />
          </div>
        </div>
        {data.order === true && (
          <div className="field">
            <label className="label"> Order Id</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="order"
                placeholder="order id"
                value={formData.order}
                onChange={handleFormChange}
              />
            </div>
          </div>
        )}
        {data.paymentMethod === true && (
          <div className="field">
            <label className="label">Pyament Method</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="paymentMethod"
                placeholder="Bank Account or UPI Id"
                value={formData.paymentMethod}
                onChange={handleFormChange}
              />
            </div>
          </div>
        )}

        {data.accountNumber === true && (
          <div className="field">
            <label className="label">Bank Account Number</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="accountNumber"
                placeholder="Bank Account Number"
                value={formData.accountNumber}
                onChange={handleFormChange}
              />
            </div>
          </div>
        )}
        {data.ifsc === true && (
          <div className="field">
            <label className="label"> Ifsc Code</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="ifsc"
                placeholder="Ifsc Code"
                value={formData.ifsc}
                onChange={handleFormChange}
              />
            </div>
          </div>
        )}
        {/* <IFSCSearch/> */}
        {data.beneficiaryName === true && (
          <div className="field">
            <label className="label"> Beneficiary Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="beneficiaryName"
                placeholder="Beneficiary Name"
                value={formData.beneficiaryName}
                onChange={handleFormChange}
              />
            </div>
          </div>
        )}
        {data.bankName === true && (
          <div className="field">
            <label className="label">Bank Name</label>
            <BankDropdown selectedBank={selectedBank} handleBankChange={handleBankChange} />
          </div>
        )}
        {data.UPI === true && (
          <div className="field">
            <label className="label">UPI ID</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="UPI"
                placeholder="UPI ID"
                value={formData.UPI}
                onChange={handleFormChange}
              />
            </div>
          </div>
        )}
        {data.product1 === true && (
          <div className='field'>
            {/* Additional input field */}
            {/* <div className='field'>
              <label className="label">{data.product1}</label>
              <div className="control">
                <div className="select is-rounded">
                  <select
                    name="product1"
                    value={formData.product1}
                    onChange={handleFormChange}

                  >
                    <option value="" disabled>Select a product</option>
                    {products.map((product) => (
                      <option key={product._id} value={product.productName}>
                        {product.productName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div> */}
            <selectedBank />
            <div className='field'>
              <label className="label">{data.inputValue1}</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="text"
                  name="inputValue1"
                  placeholder="Input Value"
                  value={formData.inputValue1}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className='field'>
              <label className="label">{data.inputValue2}</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="text"
                  name="inputValue2"
                  placeholder="Input Value"
                  value={formData.inputValue2}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className='field'>
              <label className="label">{data.inputValue3}</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="file"
                  name="inputValue3"
                  accept="image/*" // Only allow image files
                  onChange={handleFileUpload} // Use a separate handler for file uploads
                />
              </div>
            </div>
            <div className='field'>
              <label className="label">{data.inputValue4}</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="file"
                  name="inputValue4"
                  accept="image/*"

                  onChange={handleFileUpload1}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">{data.starRating}</label>
              <div className="control">
                <div className="select is-rounded">
                  <select
                    name="starRating"
                    value={formData.starRating}
                    onChange={handleFormChange}
                    required
                  >
                    <option value="" disabled>
                      Select Rating
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
              </div>
            </div>

            {/* <div className='field'>
                <label className="label">{data.sellerFeedbackScreenshot}</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="file"
                    name="sellerFeedbackScreenshot"
                    accept="image/*"
                   
                    onChange={handleFileUpload2}
                  />
                </div>
              </div> */}
            <div className='field'>
              <label className="label">{data.invoiceReceived}</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="file"
                  name="inputValue4"
                  accept="image/*"

                  onChange={handleFileUpload2}
                />
              </div>
            </div>

          </div>
        )}
        <div className='field'>
          <label className="label">{data.invoiceNumber}</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              name="invoiceNumber"
              placeholder="Invoive Number"
              value={formData.invoiceNumber}
              onChange={handleFormChange}
            />
          </div>
        </div>

        {/* Only render if whatsappGroup is true */}
        {data.whatsappGroup === true && (
          <div className="field">
            <label className="label">Have You Joined Whatsapp Group</label>
            <label className="radio">
              <input
                type="radio"
                name="whatsappGroup"
                value="yes"
                checked={formData.whatsappGroup === 'yes'}
                onChange={handleFormChange}
              />
              Yes
            </label>
            <label className="radio">
              <input
                type="radio"
                name="whatsappGroup"
                value="no"
                checked={formData.whatsappGroup === 'no'}
                onChange={handleFormChange}
              />
              No
            </label>
          </div>
        )}

        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"

            />
            Agree to{' '}
            <a href="//hobo.video/terms-conditions-for-an-influencer" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
          </label>
        </div>
        <div className="field">
          <div className="control">
            <button className="button is-primary is-fullwidth mt-5" type="submit">
              {loading ? (
                <div className="loader"></div> // Loader
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </div>
      </form>
      {showMessage && (
        <div className="thank-you-message">
          <h1>Form Submited successfully</h1>
          <p>Thank you for submitting the form!</p>
        </div>
      )}
    </div>
  );
};
export default Form2;