import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Config } from '../.env.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./style.css";

const OtpPage = () => {
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const [timer, setTimer] = useState(0);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
    } else {
      toast.error('Email is missing. Redirecting...');
      navigate('/');
    }
  }, [location.state, navigate]);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timer]);

  const handleVerifyOtp = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${Config.API_URL}/verifyOtp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ numOtp: otp }),
      });

      const data = await response.json();
      if (data.success) {
        toast.success('OTP verified successfully');
        navigate('/update-password', { replace: true, state: { email: email } });
      } else {
        toast.error(`Failed to verify OTP: ${data.message}`);
      }
    } catch (error) {
      toast.error(`Error during OTP verification: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    if (timer > 0) return;
console.log("Email being sent for OTP:", email);
    try {
      const response = await fetch(`${Config.API_URL}/resendOTP`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email }),
      });

      const data = await response.json();
      if (data.success) {
        toast.success('OTP resent successfully');
        setTimer(30);
      } else {
        toast.error(`Error: ${data.message}`);
      }
    } catch (error) {
      toast.error(`Error during OTP resend: ${error.message}`);
    }
  };

  return (
    <div className='thank-you-container'>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <div className='container mt-5 pt-5'>
        <h1 className='title has-text-white has-text-centered'>OTP Verification</h1>
        <div className='control pt-4'>
          <div className='field'>
            <label className='label has-text-white'>6 Digit OTP Received on Your Registered Email</label>
            <div className='control has-icons-left my-input'>
              <input
                className='input is-rounded'
                type='text'
                placeholder='e.g.123456'
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                maxLength={6}
              />
              <span className='icon is-small is-left'>
                <i className='fas fa-key'></i>
              </span>
            </div>
          </div>
          <div className='field is-grouped' style={{ marginLeft: '370px' }}>
            <div className='control'>
              <button
                className='button is-primary is-rounded'
                onClick={handleResendOtp}
                disabled={timer > 0}
              >
                {timer > 0 ? `Resend OTP in ${timer}s` : 'Resend OTP'}
              </button>
            </div>
            <div className='control'>
              <button className='button is-success is-rounded' onClick={handleVerifyOtp} disabled={loading}>
                {loading ? <div className="loader"></div> : 'Verify Otp'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpPage;
