import React, { useState, useEffect } from 'react';
import topImage from './img/download.jpeg';
import logo from "./img/Logo (1).png";
import { useNavigate } from 'react-router-dom';
import { Config } from '../.env.js';
import axios from 'axios';

const AllCamp = ({ campaign = "Hello", alignLeft, }) => {
    // Styles for the top image card
    const { campaignName } = campaign;
    const [product, setProduct] = useState(null);
    // const { image } = product
    useEffect(() => {
        const fetchProduct = async () => {
            try {
                console.log("Fetching product for campaignName:", campaignName);
                const response = await axios.get(`${Config.API_URL}/api/product`, {
                    params: { campaignName } // Pass campaignName as query parameter
                });
                console.log("API Response:", response.data);
                setProduct(response.data[0]); // Assuming the API returns an array, take the first product
            } catch (error) {
                console.error("Error fetching product:", error);
            }
        };

        if (campaignName) {
            fetchProduct();
        }
    }, [campaignName]);

    const topImageStyle = {
        backgroundImage: `url(${topImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '170px', // Adjust height as needed
        width: '450px', // Full width to match the button card below
        borderRadius: '6px',
        marginBottom: '-10px',
        marginLeft: '-15px',
        marginTop: '-13px',
        position: 'relative',

    };
    const logoStyle = {
        position: 'absolute',
        bottom: '100px',
        left: '5px',
        width: '40px',
        height: '36px',
        top: "130px"
    };

    const navigate = useNavigate();


    const handleApply = () => {
        navigate(`/campaignDetails/${campaignName}?campaignDetails=${campaignName}`);
    };

    const handleApplyDeliverables = () => {
        navigate(`/order-form/${campaignName}?campaignName=${campaignName}`);

    };

    const handleConfirmation = () => {
        navigate(`/order-form/${campaignName}?campaignName=${campaignName}`);
    };

    const handleOrders = () => {
        navigate(`/order-table`, { state: { campaignName } });
    };

    return (
        <div className='is-max-desktop'>
            <div >
                {/* Button Card */}
                <div className="card-content">
                    <div className="box mt-6 ml-5" style={{ width: 465, height: 450, }}>
                        <div className="box" style={topImageStyle}>
                            <img src={logo} alt="Logo" style={logoStyle} />
                        </div>
                        <br />
                        <div>
                            {/* Brand Name and Followers Criteria Section */}
                            <h1>{campaignName}</h1>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <span className="icon">
                                        <i className="fas fa-tag"></i>
                                    </span>
                                    <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Campaign Name</p>
                                    <p className='has-text-weight-bold'>{campaignName}</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                    <span className="icon" style={{ marginRight: "50px" }}>
                                        <i className="fas fa-user-friends"></i> {/* Icon for followers */}
                                    </span>
                                    <p className="has-text-grey-light " >Item Name</p>
                                    <p className='has-text-weight-bold'>{product?.productName}</p>
                                </div>
                            </div>

                            {/* deliverables and Give Products Worth Section */}


                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <span className="icon">
                                        <i className="fas fa-tag"></i>
                                    </span>
                                    <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Available Slots</p>
                                    <p className='has-text-weight-bold'>{product?.productSlot}</p>
                                </div>
                            </div>


                            <div style={{ flexDirection: 'row', gap: '10px', }}>
                                <button onClick={handleApply} className="button is-small-custom  mt-3 ml-1 has-text-info " style={{ width: '200px', height: '30px' }}>View Campaign</button>
                                <button onClick={handleApplyDeliverables} className="button is-small-custom  mt-3 ml-1 has-text-info " style={{ width: '200px', height: '30px' }}>View Order Form</button>

                                <button onClick={handleConfirmation} className="button is-small-custom  mt-2 ml-1 has-text-info " style={{ width: '200px', height: '30px' }}>View Refund Form</button>
                                <button onClick={handleOrders} className="button is-small-custom  mt-2 ml-1 has-text-info" style={{ width: '200px', height: '30px' }}>View Orders</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AllCamp;
