import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Config } from '../.env.js';
import 'react-toastify/dist/ReactToastify.css';
import "../pages/style.css";
// import ReviewUser from './ReviewrSignup.js';

const ReviewUserOtp = () => {
    const location = useLocation(); // Get state from navigation
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [email, setEmail] = useState('');
    const [timer, setTimer] = useState(0);
    const [loading, setLoading] = useState(false);

    // Extract email from location state
    useEffect(() => {
        if (location.state?.email) {
            setEmail(location.state.email);
        } else {
            toast.error('Email is missing. Redirecting...');
            navigate('/'); // Redirect if email is missing
        }
    }, [location.state, navigate]);

    const handleVerifyOtp = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${Config.API_URL}/user/ReviewerverifyOtp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    numOtp: otp,
                }),
            });

            const data = await response.json();

            if (data.success) {
                toast.success('OTP verified successfully');
                // navigate('/order-form', { replace: true });
                navigate('/live-user', { replace: true });
            } else {
                toast.error(`Failed to verify OTP: ${data.message}`);
                console.log(data.message)
            }
        } catch (error) {
            toast.error(`Error during OTP verification: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleResendOtp = async () => {
        try {
            const response = await fetch(`${Config.API_URL}/user/ReviewerresendOTP`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email, // Send email from state
                }),
            });
            const data = await response.json();
            if (data.success) {
                toast.success('OTP resent successfully');
                setTimer(30); // Example timer value
            } else {
                toast.error(`Error: ${data.message}`);
            }
        } catch (error) {
            toast.error(`Error during OTP resend: ${error.message}`);
        }
    };

    return (
        <div className='thank-you-container'>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <div className='container mt-5'>
                <h1 className='title mt-5'>OTP Verification</h1>
                <div className='control pt-4'>
                    <div className='field'>
                        <label className='label'>Enter 6-digit OTP sent to your email</label>
                        <div className='control has-icons-left'>
                            <input
                                className='input'
                                type='text'
                                placeholder='Enter OTP'
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                maxLength={6}
                            />
                        </div>
                    </div>
                    <div className='field is-grouped'>
                        <div className='control'>
                            <button className='button is-primary' onClick={handleResendOtp} disabled={timer > 0 || loading}>
                                {timer > 0 ? `Resend OTP in ${timer}s` : 'Resend OTP'}
                            </button>
                        </div>
                        <div className='control'>
                            <button className='button is-success' onClick={handleVerifyOtp}>
                                {loading ? 'Loading...' : 'Verify OTP'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewUserOtp;
